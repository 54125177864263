<template lang="pug">
  .candidates-dialog
    v-icon(small @click="toggleDialog").copy-arrow mdi-menu-down
    v-dialog(v-model="showing" :max-width="600")
      v-card
        v-card-title Exam date filters
        v-card-text
          filter-autocomplete(
            v-model="dialogFilters.city"
            :getItems="getExamLocation"
            multiple
            label="Exam Location"
            testName="exam-location"
            clearable
          )
          datepicker(
            v-model="dialogFilters.examDateAfter"
            label="Exam date from"
          )
          datepicker(
            v-model="dialogFilters.examDateBefore"
            label="Exam date to"
          )
        v-card-actions
          v-spacer
          v-btn(color="error" text @click="toggleDialog") Close
          v-btn(color="success" text @click="submitExamDate") Apply
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'

import {mapActions} from "vuex"

export default {
  name: 'examDateFiltersDialog',

  mixins: [showingMixin],

  props: {
    filters: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dialogFilters: {
      city: null,
      examDateAfter: null,
      examDateBefore: null
    }
  }),

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
    }),

    toggleDialog() {
      this.toggle()
      if (this.showing) this.initDialogFilters()
    },

    submitExamDate() {
      this.toggle()
      this.$emit('change', this.dialogFilters)
    },

    initDialogFilters() {
      for (let filter in this.dialogFilters) {
        if (filter in this.filters) {
          this.dialogFilters[filter] = this.filters[filter]
        }
      }
    }
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    datepicker: () => import('../../filters/CandidateDatepickerFilters.vue'),
  }
}
</script>
